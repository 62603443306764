<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <Button
          label="Add New"
          icon="pi pi-plus"
          class="ml-2 p-button-success"
          @click="$router.push('clients/add')"
        />
      </template>

      <template #right>
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers dee"
      :rows="5"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="show {first} to {last} from {totalRecords}"
    >
      <template #header>
        <div class="table-header">
          Kunden Aufführen

          <div style="float: right">
            <!-- <Button
              icon="pi pi-download"
              class="p-button-help"
              @click="exportCSV($event)"
            /> -->
          </div>
        </div>
      </template>
      <template #empty> No Data </template>
      <template #loading> Daten werden geladen, bitte warten... </template>
      <Column
        filterMatchMode="contains"
        field="id"
        header="ID"
        :sortable="true"
      >
        <!-- <template #filter>
              <InputText
                type="number"
                v-model="filters['id']"
                class="p-column-filter"
                placeholder="Search By ID"
              />
            </template> -->
      </Column>

      <Column
        filterMatchMode="contains"
        field="name"
        header="Name"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['name']"
            class="p-column-filter"
            placeholder="Search By Name"
          />
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="phone"
        header="Telefon"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['phone']"
            class="p-column-filter"
            placeholder="Search By Phone"
          />
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="email"
        header="Email"
        :sortable="true"
      />

      <Column
        filterMatchMode="contains"
        field="id"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('clients/massageorder/' + slotProps.data.id)"
            icon="pi pi-eye"
            class="p-button-info mr-2 p-button-rounded"
          ></Button>

          <Button
            type="button"
            @click="$router.push('clients/edit/' + slotProps.data.id)"
            icon="pi pi-pencil"
            class="p-button-success p-mr-2 p-button-rounded"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning ml-2 p-button-rounded"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],

      loading: true,
      filters: {},
    };
  },
  methods: {
    getData() {
      this.$http.get(`users`).then(
        (response) => {
          this.loading = false;
          let list = response.data.data;

          for (let item of list) {
            item.name = item.fname + " " + item.lname;
          }
          this.list = list;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    deleteItem(id) {
      this.$confirm.require({
        message: "Are you sure you want to delete records?",
        header: "Yes Delete Record",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.$http.delete(`users/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: "error",
                summary: "Done Successfully",
                detail: "Deleted successfully",
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
        },
      });
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style>
.fc .fc-view-harness {
  /* height: 856.296px !important; */
}

.fc .fc-scrollgrid-section table {
  width: 100% !important;
  /* height: 825px !important; */
}

.fc-scrollgrid-section-body .fc-scrollgrid-section table {
  width: 100% !important;
  /* height: 856.296px !important; */
}
.dee .p-paginator {
  display: none !important;
}
</style>
